import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  api_call:boolean = false;
  
  constructor( public router:Router,) { }

  ngOnInit(): void {
  }

  signIn(){
    this.router.navigate(['/dashboard']);
  }

}
