import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { AuthenticationGuard } from './authentication.guard';
import { NotfoundComponent } from './public/notfound/notfound.component';



const routes: Routes = [
    {
      path: '',
      loadChildren: () => import('./public/public.module').then( m => m.PublicModule)
    },
    {
      path: 'dashboard',
      //  canActivate:[AuthenticationGuard],
      loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule)
    },
    { path: '**', component: NotfoundComponent }
  
  ];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }